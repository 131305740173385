import { AiOutlineReload } from "react-icons/ai";
import { BiCoffeeTogo } from "react-icons/bi";
import { FaSave } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import { FiPlusCircle, FiShare2, FiStar, FiTrash2 } from "react-icons/fi";
import { IoPauseCircleOutline, IoPlayCircleOutline } from "react-icons/io5";
import { MdTune } from "react-icons/md";
import { VscCode } from "react-icons/vsc";
import { FaGuitar } from "react-icons/fa";

export const ShareIcon = FiShare2;
export const SaveIcon = FaSave;
export const ReverseIcon = FaArrowsRotate;
export const CodeIcon = VscCode;
export const NewNotesIcon = FiPlusCircle;
export const PauseIcon = IoPauseCircleOutline;
export const PlayIcon = IoPlayCircleOutline;
export const ResetIcon = AiOutlineReload;
export const BuyCoffeeIcon = BiCoffeeTogo;
export const ChangeTuningIcon = MdTune;
export const Guitar = FaGuitar;
export const StarIcon = FiStar;
export const TrashIcon = FiTrash2;